
import config from '@/utils/config'
import UrlImageHandler from '@/mixins/UrlImageHandler'
import ResizeHandler from '@/mixins/ResizeHandler'

export default {
  mixins: [UrlImageHandler, ResizeHandler],
  name: 'Popup',
  data() {
    return {
      currentPopup: {},
      popupList: [],
      popupImg: null,
      showDialog: false,
      timeout: null,
      optionImg: {
        w: 1000
      }
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        clearTimeout(this.timeout)
        if(this.popupList && this.popupList.length) {
          this.setPopup(route)
        }
      },
      deep: true,
      immediate: true
    },
    windowWidth: {
      handler: function(breakpoint) {
        if(breakpoint < 640) {
          this.optionImg.w = 640
        } else {
          this.optionImg.w = 1000
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.getPopup()
  },
  beforeDestroy() {
    this.showDialog = false
  },
  methods: {
    handleRedirect() {
      window.open(this.currentPopup.url, '_blank');
    },
    setPopup(route) {
      switch (route.fullPath) {
        case '/':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.HOME_PAGE) || {}
          break
        case '/mua-xe':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.CAR_LIST) || {}
          break
        case '/ban-xe':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.CAR_FOR_SALE) || {}
          break
        case '/tin-tuc':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.NEWS_DETAIL) || {}
          break
        case '/dang-nhap':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.LOGIN) || {}
          break
        case '/faq':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.FAQ) || {}
          break
        case '/chung-nhan-carpla':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.QUALITY_CERTIFICATION) || {}
          break
        case '/tuyen-dung':
          this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.RECRUIT) || {}
          break
        default:
          break
      }

      if(route.fullPath.includes('/xe/')) {
        this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.CAR_DETAIL) || {}
      } else if(route.fullPath.includes('/mua-xe/')) {
        this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.CAR_BRAND) || {}
      } else if(route.fullPath.includes('/tin-tuc/')) {
        this.currentPopup = this.popupList.find(item => item.screen === config.screenBanner.NEWS_DETAIL) || {}
      }

      if(this.windowWidth < 640) {
        this.popupImg = this.currentPopup.mobileImage
      } else {
        this.popupImg = this.currentPopup.image
      }

      if(this.currentPopup.image) {
        this.timeout = setTimeout(() => {
          this.showDialog = true
        }, this.currentPopup?.displayTime * 1000 || 0)
      } else {
        this.showDialog = false
      }
    },
    async getPopup() {
      clearTimeout(this.timeout)
      try {
        const { data } = await this.$store.dispatch('popup/getPopup')
        if(data && data.length) {
          this.popupList = data.filter(item => item.active)
          this.setPopup(this.$route)
        }
      } catch (err) {
        this.showDialog = false
      }
    },
    onShowDialog() {

    },
    onHideDialog() {

    }
  }
}
