
import config from '@/utils/config'
import {formatCurrency} from '@/utils'
import ResizeHandler from '@/mixins/ResizeHandler'
import CleverTapHandler from "@/mixins/CleverTapHandler";
import img1 from '@/assets/imgs/search-car/img1.svg'
import img2 from '@/assets/imgs/search-car/img2.svg'
import img0 from '@/assets/imgs/search-car/image.png'
import tick from '@/assets/imgs/search-car/tick.svg'
import querystring from "querystring";
import NuxtSSRScreenSize from "nuxt-ssr-screen-size";

const LIST_HOBBIES_URL = process.env.NUXT_ENV_API_BASE + '/psychographic/'
export default {
  mixins: [ResizeHandler, CleverTapHandler, NuxtSSRScreenSize.NuxtSSRScreenSizeMixin],
  name: 'DialogSeacrhCarV2',
  data() {
    return {
      tick,
      steps: [
        {
          id: 0,
          title: 'Carpla sẽ tư vấn cho bạn dựa trên cá tính và sở thích cá nhân của bạn',
          desc: 'Để bạn được tư vấn đúng nhu cầu nhất, Carpla sẽ tìm hiểu mong muốn về xe của bạn thông qua 2 câu hỏi ngắn.',
          img: img0,
          imgTitle: 'Hãy cho chúng tôi biết độ tuổi của bạn'
        },
        {
          id: 1,
          title: 'Độ tuổi của bạn',
          desc: 'Hãy để chúng tôi giúp bạn tìm được chiếc xe phù hợp dựa trên nhu cầu của bạn.',
          img: img1,
          imgTitle: 'Hãy cho chúng tôi biết độ tuổi của bạn'
        },
        {
          id: 2,
          title: 'Bạn mong muốn gì ở chiếc xe?',
          desc: 'Hãy để chúng tôi giúp bạn tìm được chiếc xe phù hợp dựa trên nhu cầu của bạn (tối thiểu 3 mục)',
          img: img2,
          imgTitle: 'Bạn mong muốn gì ở chiếc xe?'
        },
      ],
      activeStep: 0,
      loading2: false,
      age: '',
      hobbies: [],
      ageList: config.ageList,
      hobbiesList: [],
      isEnoughHob: false,
      start: true,
      //-----------
      loading: false,
      showDialog: false,
      childDialogVisible: false,
      keyDialog: 1,
      keyDialogSuccess: 1
    }
  },
  watch: {
    age: {
      handler: async function (age) {
        await this.getHobbies(age)
      },
      deep: true, immediate: true
    },
  },
  mounted() {
    this.$nuxt.$on('toggleSearchCarV2', () => {
      this.form = {}
      this.showDialog = true
    })
  },
  methods: {
    onShowDialog() {
      if (this.$vssWidth < 640) {
        this.$refs.dialog1.maximize()
      }
    },
    closeChildDialog() {
      this.showDialog = false
      this.childDialogVisible = false
    },
    async getHobbies() {
      if (this.age) {
        try {
          this.loading = true
          const {styles} = await this.$axios.$get(LIST_HOBBIES_URL + this.age)
          this.hobbiesList = styles || []
          this.loading = false
        } catch (e) {
          this.loading = false
          console.log(e)
          return []
        }
      }
      this.loading = false
    },
    onHideDialog() {
      this.loading = false
      this.loading2 = false
      this.hobbies = []
      this.age = ''
      this.activeStep = 0
      this.keyDialog++
    },
    prev() {
      if (this.activeStep > 1) {
        this.activeStep--
        this.hobbies = []
      }
    },
    selectAge(ageValue) {
      this.age = ageValue

    },
    checkHobbiesSelected(item) {
      return this.hobbies.includes(item);
    },
    selectHobbies(item) {
      if (this.hobbies.includes(item)) {
        for (const index in this.hobbies) {
          if (this.hobbies[index] === item) this.hobbies.splice(index, 1)
        }
      } else {
        this.hobbies.push(item)
      }
    },
    handleStep1() {
      this.activeStep++
    },
    readNow() {
      this.start = false
      if (this.hobbies.length >= 3) {
        this.isEnoughHob = true
        this.loading2 = true
        const hobbiesString = this.hobbies.join(',')
        const obj = {
          age: this.age,
          hobbies: hobbiesString
        }
        this.$router.push(`/ket-qua-tim-xe?${querystring.stringify(obj)}`)
        setTimeout(() => {
          this.showDialog = false
        }, 500)
      } else {
        this.isEnoughHob = false
      }
    },
  }
}
