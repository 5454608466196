
import config from '@/utils/config'
import {formatCurrency} from '@/utils'
import ResizeHandler from '@/mixins/ResizeHandler'
import CleverTapHandler from "@/mixins/CleverTapHandler";

export default {
  mixins: [ResizeHandler, CleverTapHandler],
  name: 'DialogValuation',
  data() {
    return {
      loading: false,
      showDialog: false,
      showDialogSuccess: false,
      form: {},
      attributes: [],
      brands: [],
      min: 0,
      max: 0,
      formData: {},
      keyDialog: 1,
      keyDialogSuccess: 1
    }
  },
  created() {
    this.getConfigValuation()
  },
  mounted() {
    this.$nuxt.$on('toggleValuationCar', () => {
      this.form = {}
      this.showDialog = true
    })
  },
  methods: {
    onShowDialog() {
      // if (this.windowWidth < 640) {
      //   this.$refs.dialog.maximize()
      // }
    },
    onHideDialog() {
      this.keyDialog++
    },
    onShowDialogSuccess() {
      // if (this.windowWidth < 640) {
      //   this.$refs.dialogSuccess.maximize()
      // }
    },
    onHideDialogSuccess() {
      this.keyDialogSuccess++
    },
    redirectSellCar() {
      this.showDialogSuccess = false
      this.$router.push('/ban-xe')
    },
    formatPrice(price) {
      return formatCurrency(price)
    },
    onChangeAttribute(e) {
      const manufacturers = (this.attributes || []).find(item => item.prop === 'manufacturer') || {}
      const obj = (manufacturers.options || []).find(item => item.value === e.value)
      if (obj && obj.options) {
        this.brands = obj.options
      }
    },
    async getConfigValuation() {
      // try {
      //   const {items} = await this.$axios.$get(config.api.valuation + '/config')
      //   this.attributes = items
      // } catch (err) {
      //
      // }
    },
    async submit() {
      try {
        this.loading = true
        this.formData = JSON.parse(JSON.stringify(this.form))
        const {min, max} = await this.$axios.$post(config.api.valuation, {
          ...this.form
        })
        this.clevertapTrack(config.eventTypeTracking.car_valuation_form_filled, {
          brand_advise_filled: this.form.brand,
          cartype_advise_filled: this.form.manufacturer,
          year_advise_filled: this.form.year,
          city_advise_filled: this.form.address,
          kilomet_advise_filled: this.form.odo,
          fuel_advise_fill: this.form.fuel,
          gearbox_advise_fill: this.form.gear,
          status: 'succeed',
          button_clicked: 'Click vào button định giá xe',
          screen_name: 'Valuation'
        })
        this.loading = false
        this.min = min ? parseInt(min) * 1000000 : 0
        this.max = max ? parseInt(max) * 1000000 : 0
        this.showDialog = false
        this.showDialogSuccess = true
      } catch (err) {
        this.loading = false
        this.clevertapTrack(config.eventTypeTracking.car_valuation_form_filled, {
          brand_advise_filled: this.form.brand,
          cartype_advise_filled: this.form.manufacturer,
          year_advise_filled: this.form.year,
          city_advise_filled: this.form.address,
          kilomet_advise_filled: this.form.odo,
          fuel_advise_fill: this.form.fuel,
          gearbox_advise_fill: this.form.gear,
          status: 'falure',
          button_clicked: 'Click vào button định giá xe',
          screen_name: 'Valuation'
        })
      }
    }
  }
}
