
import viFlag from 'assets/imgs/vn-flag.png'
import enFlag from 'assets/imgs/en-flag.png'

export default {
  name: 'LanguageBox',
  data() {
    return {
      selectedLanguage: 'vi',
      languages: [
        {name: 'Tiếng Việt', code: 'vi', img: viFlag },
        {name: 'English', code: 'en', img: enFlag },
      ]
    }
  },
  methods: {
    toggleLanguage(event) {
      this.$refs.op.toggle(event);
    }
  }
}
