
import LoginMethods from "../LoginMethods/LoginMethods.vue";
import config from "../../utils/config";
import RudderStackHandler from "../../mixins/RudderStackHandler";
import {ACTION_GET_OAUTH_CLIENT} from "../../utils/store.action";

export default {
  name: 'SignInDialog',
  mixins: [RudderStackHandler],
  components: {LoginMethods},
  data() {
    return {
      displayModal: false,
      mobile: '',
      loading: false,
      client_id: this.$route.query.client_id || '',
      grant_type: this.$route.query.grant_type || '',
      state: this.$route.query.state || '',
      from: this.$route.query.from || '',
      response_type: this.$route.query.response_type || '',
      redirect_uri: this.$route.query.redirect_uri || '',
      isOauth: false,
      appName: '',
      isLoggedIn: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['authen/userInfo']
    }
  },
  async mounted() {
    // this.rudderPage()
    localStorage.setItem("query", location.search)
    this.isOauth = !!(this.client_id && this.grant_type && this.state && this.response_type && this.redirect_uri && this.from);
    if (this.isOauth && process.client) {
      await this.getClientName();
    }
  },
  methods: {
    toggleDialog() {
      if (this.$route.path === '/dang-nhap' || this.$route.path === '/otp') {

      } else {
        this.displayModal = !this.displayModal
      }
    },
    async getClientName() {
      const res = await this.$store.dispatch(ACTION_GET_OAUTH_CLIENT, this.client_id)
      if (res && res.name) {
        this.appName = res.name
      } else {
        window.location.href = "/dang-nhap"
      }
    },
    blockSpecialCharSDT: function (e) {
      let k = e.keyCode;
      if (k >= 48 && k <= 57) {
        return e.returnValue = true
      } else {
        return e.returnValue = false
      }
    },
    hidePopup() {
      this.mobile = ''
      this.appName = ''
      this.displayModal = false
    },
    handleSubmit(invalid) {
      if (!invalid) {
        if (this.isOauth) {
          this.$router.push('/otp?mobile=' + this.mobile + '&client_id=' + this.client_id + '&grant_type=' + this.grant_type + '&state=' + this.state + '&response_type=' + this.response_type + '&redirect_uri=' + this.redirect_uri + '&from=' + this.from)
          this.hidePopup()
        } else {
          this.$router.push(`/otp?mobile=${this.mobile}`)
          this.hidePopup()
        }
      }
    },
    handleOauth() {
      this.$router.push('/oauth?client_id=' + this.client_id + '&grant_type=' + this.grant_type + '&state=' + this.state + '&response_type=' + this.response_type + '&redirect_uri=' + this.redirect_uri + '&from=' + this.from)
      this.hidePopup()
    },
    saveLocalStorageOauthVetc() {
      localStorage.setItem('client_id', this.client_id)
      localStorage.setItem('grant_type', this.grant_type)
      localStorage.setItem('state', this.state)
      localStorage.setItem('response_type', this.response_type)
      localStorage.setItem('redirect_uri', this.redirect_uri)
    }
  }
}
